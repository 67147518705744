import { axios, API_ROUTES } from "@/config/index.js";

export default {

  async getAll(uuid) {
    let result = await axios.get(`${API_ROUTES.row.get}/${uuid}`);

    return result;
  },

  async save(id, data) {
    let result = await axios.post(`${API_ROUTES.row.save}`, data);

    return result;
  },

  async update(id, data) {
    let result = await axios.put(`${API_ROUTES.row.update}/${id}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.row.delete}/${id}`);

    return result;
  },
}